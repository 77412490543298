import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import '../../sass/components/toast.scss';

const Toast = ({
    className,
    variant,
    children,
    heading,
}) => {
    // Class definitions
    const baseClass = 'toast';
    const variantClass = variant ? `toast--${variant}` : '';
    const customClass = className;
    const classes = [baseClass, variantClass, customClass].filter(Boolean).join(' ');

    return (
        <div className={classes}>
            <div className='toast__inner'>
                {heading && (
                    <h3 className='toast__heading'>{heading}</h3>
                )}
                
                <div className='toast__description'>
                    {children}
                </div>
            </div>
        </div>
    );
};

Toast.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(['warning', 'error', 'success']),
    children: PropTypes.node.isRequired,
    heading: PropTypes.string,
};

export default Toast;
