import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';

// Component Imports
import Loader from '../loader';
import Toast from '../toast';
import Form from '../form';
import ContactField from './field';

// Style Imports
import '../../sass/components/contact-form.scss';

const ContactForm = () => {
    const [state, setState] = useState([{
        name: '',
        email: '',
        subject: '',
        organisation: '',
        message: '',
        disableForm: false,
        formSubmitted: false,
        formError: false,
    }]);

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    function sendEmail() {
        setState({...state, disableForm: true});
        
        let templateParams = {
            from_name: state.email,
            to_name: 'james_parker_gmail',
            name: state.name,
            organisation: state.organisation,
            subject: state.subject,
            message: state.message,
        }

        emailjs.send('james_parker_gmail', 'moneybeans', templateParams, 'user_SuOp1XvItI850LGO5kBSu')
        .then(() => {
            setState({
                ...state,
                formError: false,
                formSubmitted: true,
                disableForm: false,
            });
        }, () => {
            setState({
                ...state,
                formError: true,
                formSubmitted: false,
                disableForm: false,
            });
        }); 
    };

    return (
        <form
            className='contact-form'
            onSubmit={handleSubmit(sendEmail)}
            noValidate
        >
            <Form.Fieldset className='accessible' legend='Send us a message'>
                <div className='contact-form__inner'>
                    <ContactField
                        labelText='Full name'
                        id='full-name'
                        name='full-name'
                        placeholder='John Smith'
                        onChange={(event) => setState({...state, name: event.target.value})}
                        required
                        disabled={state.disableForm ? true : false}
                        maxLength={150}
                        type='text'
                        hasError={!!errors['full-name']}
                        {...register('full-name', {required: true})}
                        errorMessage='Please enter your full name'
                    />

                    <ContactField
                        id='email-address'
                        labelText='Email address'
                        placeholder='john.smith@gmail.com'
                        onChange={(event) => setState({...state, email: event.target.value})}
                        required
                        name='email-address'
                        disabled={state.disableForm ? true : false}
                        maxLength={150}
                        type='email'
                        hasError={!!errors['email-address']}
                        {...register('email-address', {required: true, pattern: /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                        errorMessage='Please enter a valid email address'
                    />

                    <ContactField
                        id='subject'
                        labelText='Subject'
                        placeholder='Hi there!'
                        onChange={(event) => setState({...state, subject: event.target.value})}
                        required
                        name='subject'
                        disabled={state.disableForm ? true : false}
                        maxLength={100}
                        type='text'
                        hasError={!!errors['subject']}
                        {...register('subject', {required: true})}
                        errorMessage='Please enter a subject'
                    />

                    <ContactField
                        id='organisation-name'
                        labelText='Organisation name'
                        name='organisation-name'
                        placeholder='Big business Ltd.'
                        onChange={(event) => setState({...state, organisation: event.target.value})}
                        disabled={state.disableForm ? true : false}
                        maxLength={100}
                        type='text'
                    />

                    <ContactField
                        labelText='Message'
                        id='message'
                        type='text'
                        name='message'
                        onChange={(event) => setState({...state, message: event.target.value})}
                        hasError={!!errors['message']}
                        placeholder='I just wanted to say...'
                        required
                        disabled={state.disableForm ? true : false}
                        variant='textarea'
                        {...register('message', {required: true})}
                        errorMessage='Please enter your message'
                    />

                    <input
                        className='button'
                        type='submit'
                        value='Submit'
                        disabled={state.disableForm ? true : false}
                    />

                    { state.disableForm && (
                        <Loader text='Sending message...' />
                    )}
                    
                    {(state.formSubmitted || state.formError) && (
                        <Toast variant={state.formError ? 'error' : 'success'}>
                            {state.formError ? 'There was an issue submitting your message, please try again.' : "Success - your message has been sent!"}
                        </Toast>
                    )}
                </div>
            </Form.Fieldset>
        </form>
    );
};

export default ContactForm;