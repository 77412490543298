import React from "react";
import { withPrefix, graphql } from "gatsby";

// Layouts
import Default from '../layouts/default';
import Seo from '../layouts/seo';

// Components
import PageHeader from '../components/page-header';
import Section from '../components/section';
import ContactForm from '../components/contact-form';
import Tips from '../components/tips';

const Contact = ({ data }) => {
  const { markdownRemark: pageInfo } = data;
  const headerImage = pageInfo.frontmatter.image.relativePath;

  return (
    <Default>
      <Seo
        title={pageInfo.frontmatter.title}
        description={pageInfo.frontmatter.description}
      />

      <PageHeader
        heading={pageInfo.frontmatter.title}
        imageSource={`${withPrefix("/")}img/${headerImage}`}
        description={pageInfo.frontmatter.description}
      />

      <Section
				label='Contact form'
				variants={['padded']}
			>
        <ContactForm />
      </Section>

      <Tips
        text={(
          <>
            <h2><span role='img' aria-hidden='true'>&#128172;</span> Find us on social media</h2>
            <p>You can follow moneyBeans and contact us on our social media channels!</p>
          </>
        )}
      />
    </Default>
  );
};

export default Contact;

export const ContactQuery = graphql`
  query contact($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        image {
            relativePath
        }
        description
      }
    }
  }
`;